exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-fachkraft-tsx": () => import("./../../../src/pages/fachkraft.tsx" /* webpackChunkName: "component---src-pages-fachkraft-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-karriere-tsx": () => import("./../../../src/pages/karriere.tsx" /* webpackChunkName: "component---src-pages-karriere-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-leistungen-tsx": () => import("./../../../src/pages/leistungen.tsx" /* webpackChunkName: "component---src-pages-leistungen-tsx" */),
  "component---src-pages-leitbild-tsx": () => import("./../../../src/pages/leitbild.tsx" /* webpackChunkName: "component---src-pages-leitbild-tsx" */),
  "component---src-pages-pflegedienstleitung-tsx": () => import("./../../../src/pages/pflegedienstleitung.tsx" /* webpackChunkName: "component---src-pages-pflegedienstleitung-tsx" */),
  "component---src-pages-pflegefachhelfer-tsx": () => import("./../../../src/pages/pflegefachhelfer.tsx" /* webpackChunkName: "component---src-pages-pflegefachhelfer-tsx" */),
  "component---src-pages-pflegehelfer-tsx": () => import("./../../../src/pages/pflegehelfer.tsx" /* webpackChunkName: "component---src-pages-pflegehelfer-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

